.field {
  margin-bottom: 1rem;
  padding: 0.5rem;
}


.p-floatlabel input {
  width: 100%;
}

.p-floatlabel p-select {
  width: 100%;
}
