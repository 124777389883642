* {
  box-sizing: border-box;
}

html {
  font-size: $fontSize;
  border-color: #e2e7fa;
}

h1 {
  @apply text-dark-blue
}

body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply text-gray-blue font-sans
}

a {
  @apply text-royal-blue cursor-pointer
}

html {
  overflow-y: hidden;
}
