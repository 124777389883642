// To remove the default box shadow that conflicts with the custom border radius.
.p-dialog:not(.p-confirm-dialog) {
  box-shadow: none;
  border-radius: $borderRadius;

  .p-dialog-content {
    padding: 0;
    border-radius: inherit;
  }
}

.hint-tooltip .p-tooltip-text {
  width: 400px;
  height: 200px;
}

// Override style for google autocomplete
.pac-container {
  z-index: 9999999;
}
