@layer tailwind-base, primeng;

@import "tailwindcss/base.css" layer(tailwind-base);

@tailwind utilities;

@layer tailwind-base {
  .flex-c {
    @apply flex flex-col;
  }

  .flex-r {
    @apply flex flex-row;
  }

  .flex-col-center {
    @apply flex-c items-center justify-center;
  }

  .flex-row-center {
    @apply flex-r items-center justify-center;
  }
}

.error-message {
  color: #e24c4c;
  padding-top: 1rem;
}

//TODO: To remove when fixed by primeng
.p-checkbox {
  display: flex;
}

.StripeElement {
  font-family: var(--font-family);
  font-feature-settings: var(--font-feature-settings, normal);
  font-size: 1rem;
  color: var(--text-color);
  background: var(--surface-a);
  padding: 0.75rem 0.75rem;
  border: 1px solid #d1d5db;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  appearance: none;
  border-radius: 6px;
}

.StripeElement--placeholder {
  color: rgb(107, 114, 128);
}

.StripeElement--focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #BFDBFE;
  border-color: var(--primary-color);
}

.StripeElement--invalid {
  --tw-ring-color: rgb(239, 68, 68);
  border-color: #e24c4c;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
