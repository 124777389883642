/* General */
$borderRadius: 12px;
$transitionDuration: .2s;

/* Spacing */
$defaultSpacing: 1rem;

/* Font */
$fontSize: 16px;


/* Color */
$textColor: #495057;
$menuitemActiveBg: #e9ecef;
$stiilt-warning: #FF6961;


